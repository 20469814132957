import FormSectionToggler from '~/javascript/application/shared/FormSectionToggler'

export default function () {
  const sectionsToToggle = document.querySelectorAll('.form-section-to-toggle-container')

  sectionsToToggle.forEach(section => {
    const formSectionToggler = new FormSectionToggler(section)
    formSectionToggler.init()
  })
}
