// jQuery dependent checkboxes/radio buttons
export default class DependentCheckableInput {
  constructor ({ input, dependencies }) {
    this.input = input // checkbox or radio button
    this.dependencies = dependencies // Dependent inputs
  }

  init () {
    this._bindChecked()
    this._bindDependenciesUnchecked()
  }

  // private methods

  // When the checkbox/radio button is checked, it should ensure the dependent
  // input(s) are also checked.
  _bindChecked () {
    this.input.change(event => {
      if (event.target.checked) { // when checked
        this._checkDependencies()
      }
    })
  }

  // When the dependency is unchecked, it should uncheck the
  //  checkbox/radio button.
  _bindDependenciesUnchecked () {
    this.dependencies.change(event => {
      if (!event.target.checked) { // when unchecked
        this._uncheck()
      }
    })
  }

  _checkDependencies () {
    this.dependencies.prop('checked', true)
  }

  _uncheck () {
    this.input.prop('checked', false)
  }
}
