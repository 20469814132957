import $ from 'jquery'

import Quill from 'quill'
import { NOTICE_QUILL_FORMATS } from '~/javascript/shared/constants'

let quill

export default function () {
  const container = document.querySelector('#notice-content')
  // const toolbar = document.querySelector('.toolbar')

  quill = new Quill(container, {
    theme:   'snow',
    headers: {},
    formats: NOTICE_QUILL_FORMATS,
    modules: {
      toolbar: [
        [{ header: [4, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        ['link'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }],
        ['blockquote'],
        ['clean'],
      ],
    },
  })
  quill.setContents(JSON.parse($('#notice_content').val()))

  $('#edit-notice').submit(function () {
    $('#notice_content').val(JSON.stringify(quill.getContents()))
  })
}
