import accordionCollapser from '~/javascript/application/AccordionCollapser'
import SessionExpire from '~/javascript/application/SessionExpire'

import appointmentInit from '~/javascript/application/appointment/init'
import budgetingServiceInit from '~/javascript/application/budgeting_service/init'
import autoDeleteSettingsInit from '~/javascript/application/auto_delete_settings/init'
import caseGoalsCheckInInit from '~/javascript/application/case_goals_check_in/init'
import casesInit from '~/javascript/application/cases/init'
import clientInit from '~/javascript/application/client/init'
import msdSettingsInit from '~/javascript/application/msd_settings/init'
import newCaseInit from '~/javascript/application/cases/new/init'
import userInit from '~/javascript/application/user/init'
import noticeInit from '~/javascript/application/notice/init'

import setupFormConditionalExplanation from '~/javascript/application/forms/form-conditional-explanation'
import setupFormPassword from '~/javascript/application/forms/form-password'
import setupFormReset from '~/javascript/application/forms/form-reset'
import setupFormValidation from '~/javascript/application/forms/form-validation'
import setupAgeRange from '~/javascript/application/forms/form-age-range'
import setupFormClientType from '~/javascript/application/forms/form-client-type'
import setupFormPermissionMatrix from '~/javascript/application/forms/form-permission-matrix'

import Rails from '@rails/ujs'
import $ from 'jquery'

import 'foundation-sites'

window.$ = $

if (!window._rails_loaded) {
  Rails.start()
}
// bind csrf token is globally accessible place for JS
window.csrf = {
  token: $('head meta[name="csrf-token"]').attr('content'),
}

$(() => {
  setupFormValidation()
  $(document).foundation()

  accordionCollapser()

  const _sessionExpiry = new SessionExpire()

  // initialize sub-modules where appropriate, allowing us to organise the
  // jquery as opposed to loading everything in here every time.
  const path = window.location.pathname
  if (path.startsWith('/budgeting_services/')) {
    if (path.endsWith('/new') || path.endsWith('/edit')) {
      budgetingServiceInit()
    } else if (path.endsWith('/auto_delete_settings')) {
      autoDeleteSettingsInit()
    }
  // users edit & new pages
  } else if (path.startsWith('/users/')) {
    userInit()
  } else if (path.startsWith('/clients/')) {
    if (path.endsWith('/cases/new')) {
      newCaseInit()
    } else if (path.endsWith('/new') || path.endsWith('/edit')) {
      clientInit()
    } else if (path.endsWith('/cases')) {
      casesInit()
    }
  } else if (path.startsWith('/appointments')) {
    appointmentInit()
  } else if (path.startsWith('/notice')) {
    noticeInit()
  }

  if (path.endsWith('/msd_settings/edit')) {
    msdSettingsInit()
  }

  if (path.endsWith('/case_goals_check_in')) {
    caseGoalsCheckInInit()
  }

  setupFormConditionalExplanation()
  setupFormReset()
  setupFormPassword()
  setupAgeRange()
  setupFormClientType()
  setupFormPermissionMatrix()
})
