import $ from 'jquery'

export default class ConfirmGoalCompleted {
  constructor () {
    this.checkInGoalForm = $('form.unresolved_goals')
    this.saveButton = $('a.confirm_save')
  }

  init () {
    this.saveButton.on('click',
      this._submitForm.bind(this))
  }

  // private methods

  _submitForm () {
    this.checkInGoalForm.submit()
  }
}
