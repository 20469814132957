import $ from 'jquery'

// For when a note should only be shown if an input field(s) have changed

export default function () {
  function allValuesSame (elements) {
    const values = $(elements).map(function (_idx, element) {
      return $(element).val()
    })

    return $.uniqueSort(values).length === 1
  }

  function valuesMatch (currentValue, originalValue) {
    return currentValue === originalValue
  }

  function watchInputs (inputs, explanation) {
    inputs.each(function (_idx, input) {
      const originalValue = $(input).data('original-value')

      $(input).on('input', function () {
        const currentValue = $(input).val()

        if (valuesMatch(currentValue, originalValue) && allValuesSame(inputs)) {
          $(explanation).addClass('hide')
        } else {
          $(explanation).removeClass('hide')
        }
      })
    })
  }

  function watchSections (sections) {
    sections.each(function (_idx, section) {
      const inputsToWatch = $(section).find('.input-to-watch')
      const conditionalExplanation = $(section).find('.conditional-explanation')

      watchInputs(inputsToWatch, conditionalExplanation)
    })
  }

  const sections = $('.section-with-conditional-explanation')
  watchSections(sections)
}
