export default class AutoDiscardCallout {
  constructor ({ callout, calloutPeriod, enableCheckbox, periodSelect }) {
    this.callout = callout
    this.calloutPeriod = calloutPeriod
    this.enableCheckbox = enableCheckbox
    this.periodSelect = periodSelect
  }

  init () {
    this.enableCheckbox.on('change',
      this._updateCalloutVisibility.bind(this))

    this.periodSelect.on('change',
      this._updateCalloutPeriod.bind(this))

    this._updateCalloutVisibility()
    this._updateCalloutPeriod()
  }

  // private methods

  _updateCalloutVisibility () {
    if (this.enableCheckbox.is(':checked')) {
      this.callout.show()
    } else {
      this.callout.hide()
    }
  }

  _updateCalloutPeriod () {
    const chosenPeriodText = this.periodSelect.find('option:selected').text()
    this.calloutPeriod.text(chosenPeriodText)
  }
}
