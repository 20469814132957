// This removes/add validation to the mentoring permissions based on whether financial mentor checkbox is checked or not

import $ from 'jquery'

let allPermissionInputs = []
let mentoringPermissionRadioButtons = []

export default function () {
  mentoringPermissionRadioButtons = $('[name="user[mentoring_permission]"]')
  allPermissionInputs = $('input[type="checkbox"], input[type="radio"]')

  const formWithDependentInputs = $('#financial_mentor_permissions').closest('form').attr('id') === 'enable_dependent_inputs'

  if (formWithDependentInputs) {
    validatePresenceOfMentoringPermissions(mentoringPermissionRadioButtons)
    checkOrUncheckFinancialMentorCheckbox(mentoringPermissionRadioButtons)
    uncheckAndDisableDependentInputs(allPermissionInputs)

    allPermissionInputs.on('change', function () {
      uncheckAndDisableDependentInputs(allPermissionInputs)
    })
  }
}

const validatePresenceOfMentoringPermissions = (mentoringPermissionRadioButtons) => {
  $('#financial_mentor_permissions').on('change', function () {
    if ($(this).is(':checked')) {
      mentoringPermissionRadioButtons.each(function () {
        $(this).removeAttr('data-abide-ignore')
      })
    } else {
      mentoringPermissionRadioButtons.each(function () {
        $(this).attr('data-abide-ignore', true)

        $((this).closest('form')).foundation('removeErrorClasses', $('[data-form-error-for="user_mentoring_permission_mixed"]'))
        // Unselect all mentoring permissions
        $(this).prop('checked', false)
      })
    }
  })
}

const checkOrUncheckFinancialMentorCheckbox = (mentoringPermissionRadioButtons) => {
  $(mentoringPermissionRadioButtons).on('change', function () {
    if ($(this).is(':checked')) {
      $('#financial_mentor_permissions').prop('checked', true)
    }
  })
}

const uncheckAndDisableDependentInputs = (allPermissionInputs) => {
  if ($('#service_manager').is(':checked')) {
    disableAndUncheckInput('#client_administrator')
    disableAndUncheckInput('#user_mentoring_permission_restricted')
  } else if ($('#user_mentoring_permission_restricted').is(':checked')) {
    disableAndUncheckInput('#client_administrator')
    disableAndUncheckInput('#service_manager')
  } else if ($('#client_administrator').is(':checked')) {
    disableAndUncheckInput('#service_manager')
    disableAndUncheckInput('#user_mentoring_permission_restricted')
  } else {
    allPermissionInputs.each(function () {
      $('#service_manager').attr('disabled', false)
      $('#client_administrator').attr('disabled', false)
      $('#user_mentoring_permission_restricted').attr('disabled', false)
    })
  }
}

const disableAndUncheckInput = (input) => {
  $(input).prop('checked', false)
  $(input).attr('disabled', true)
}
