import $ from 'jquery'

const START_TIME_SHARED_ERROR_ID = '#appointment_start_time_error'
const START_TIME_INPUTS_SELECTOR = `
  #appointment_start_time_hour,
  #appointment_start_time_minute,
  #appointment_start_time_period`
const END_TIME_SHARED_ERROR_ID = '#appointment_end_time_error'
const END_TIME_INPUTS_SELECTOR = `
  #appointment_end_time_hour,
  #appointment_end_time_minute,
  #appointment_end_time_period`
const NO_SHOW_TRUE_SELECTOR = 'appointment_no_show_true'
const NO_SHOW_FALSE_SELECTOR = 'appointment_no_show_false'
const OPTIONAL_TIME_ASTERIX_SECTIONS = '.optional-time-asterix'
const OPTIONAL_TIME_TEXT_SECTIONS = '.optional-time-text'

/*
 * Foundation validations
 *
 * The Appointment form contains a three-selector time field (hour, minute, am/pm).
 * When one of the selectors is changed,
 * 1. If there are existing server-side errors, clear them for that input
 * 2. Remove the event handler
 * 3. Re-trigger validations on the other time selector fields
 *    (note: this will have the effect of removing any existing errors on those fields).
 */
export default class AppointmentFormTimePeriod {
  constructor (formSelector) {
    this.formSelector = formSelector
  }

  init () {
    $(START_TIME_INPUTS_SELECTOR)
      .on('valid.zf.abide invalid.zf.abide', this._getStartTimeHandler())

    $(START_TIME_INPUTS_SELECTOR).on('change', this._updateEndTime)

    this._toggleTimeOptional()
    $('#' + NO_SHOW_TRUE_SELECTOR).on('change', this._toggleTimeOptional)
    $('#' + NO_SHOW_FALSE_SELECTOR).on('change', this._toggleTimeOptional)

    $(END_TIME_INPUTS_SELECTOR)
      .on('valid.zf.abide invalid.zf.abide', this._getEndTimeHandler())
  }

  _startTimeValidatedOnce (event) {
    $(START_TIME_SHARED_ERROR_ID).remove() // remove the server-side errors

    // remove handler so it doesn't trigger again
    $(START_TIME_INPUTS_SELECTOR)
      .off('valid.zf.abide invalid.zf.abide', this._getStartTimeHandler())

    // re-trigger validation across sibling period inputs
    const $siblingInputs = $(START_TIME_INPUTS_SELECTOR).not(event.target)
    $(this.formSelector).foundation('validateInput', $siblingInputs)
  }

  _endTimeValidatedOnce (event) {
    $(END_TIME_SHARED_ERROR_ID).remove() // remove the server-side errors

    // remove handler so it doesn't trigger again
    $(END_TIME_INPUTS_SELECTOR)
      .off('valid.zf.abide invalid.zf.abide', this._getEndTimeHandler())

    // re-trigger validation across sibling period inputs
    const $siblingInputs = $(END_TIME_INPUTS_SELECTOR).not(event.target)
    $(this.formSelector).foundation('validateInput', $siblingInputs)
  }

  _getStartTimeHandler () {
    if (!this._startTimeHandler) {
      this._startTimeHandler = event => this._startTimeValidatedOnce(event)
    }

    return this._startTimeHandler
  }

  _getEndTimeHandler () {
    if (!this._endTimeHandler) {
      this._endTimeHandler = event => this._endTimeValidatedOnce(event)
    }

    return this._endTimeHandler
  }

  _updateEndTime () {
    let hourValue = document.getElementById('appointment_start_time_hour').value
    const minuteValue = document.getElementById('appointment_start_time_minute').value
    let periodValue = document.getElementById('appointment_start_time_period').value

    if (hourValue === '11') {
      hourValue = 0
      periodValue === 'PM' ? periodValue = 'AM' : periodValue = 'PM'
    } else {
      hourValue++
    }

    document.getElementById('appointment_end_time_hour').value = hourValue
    document.getElementById('appointment_end_time_minute').value = minuteValue
    document.getElementById('appointment_end_time_period').value = periodValue
  }

  _toggleTimeOptional () {
    // If appointment is a no show then indicate the time is optional
    if (document.getElementById(NO_SHOW_TRUE_SELECTOR).checked) {
      document.querySelectorAll(OPTIONAL_TIME_ASTERIX_SECTIONS).forEach(section => {
        $(section).hide()
      })
      document.querySelectorAll(OPTIONAL_TIME_TEXT_SECTIONS).forEach(section => {
        $(section).show()
      })
    } else if (document.getElementById(NO_SHOW_FALSE_SELECTOR).checked) {
      document.querySelectorAll(OPTIONAL_TIME_ASTERIX_SECTIONS).forEach(section => {
        $(section).show()
      })
      document.querySelectorAll(OPTIONAL_TIME_TEXT_SECTIONS).forEach(section => {
        $(section).hide()
      })
    }
  }
}
