import $ from 'jquery'

/**
 * For collapsing an accordion from within the accordion itself
 * The collapse is triggered by clicking an element within the accordion which
 * has class .collapse-accordion.
 */
export default function accordionCollapser () {
  const accordions = $('.accordion')

  $(accordions).each(function (_idx, accordion) {
    const accordionItems = $(accordion).find('.accordion-item')

    $(accordionItems).each(function (_idx, accordionItem) {
      const accordionContent = $(accordionItem).find('.accordion-content').first()
      const collapseButtons = $(accordionItem).find('.collapse-accordion')

      $(collapseButtons).each(function (_idx, collapseButton) {
        $(collapseButton).click(function () {
          $(accordion).foundation('toggle', accordionContent)
        })
      })
    })
  })
}
