import $ from 'jquery'

/**
 * Section toggler that shows/hides a section on button click and re-setting abide validation accordingly.
 * This is very similar to the FormSectionToggler, but operates on regular content, not a form.
 *
 * NOTES:
 * - The section(s) to be hidden/revealed is wrapped in the class
 *   .section-to-toggle
 * - The toggler takes the class .section-toggler and wraps two buttons,
 *     .section-button__expand & .section-button__collapse.
 * - Add a Foundation .hide class (etc) to hide the expand or collapse button,
 *   depending on whether or not the section is expanded/collapsed by default.
 */

export default class SectionToggler {
  constructor (containerElement) {
    this.container = containerElement
    this.sectionToToggle = $(this.container).find('.section-to-toggle')
    this.toggler = $(this.container).find('.section-toggler')
    this.expandButton = $(this.toggler).find('.section-button__expand')
    this.collapseButton = $(this.toggler).find('.section-button__collapse')
  }

  init () {
    this._bindSectionToggle()
  }

  // private methods

  _expandSection () {
    $(this.sectionToToggle).removeClass('hide')
    $(this.expandButton).addClass('hide')
    $(this.collapseButton).removeClass('hide')
  }

  _collapseSection () {
    $(this.sectionToToggle).addClass('hide')
    $(this.collapseButton).addClass('hide')
    $(this.expandButton).removeClass('hide')
  }

  _bindSectionToggle () {
    $(this.toggler).click(() => {
      if ($(this.sectionToToggle).hasClass('hide')) {
        this._expandSection()
      } else {
        this._collapseSection()
      }
    })
  }
}
