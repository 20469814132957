import $ from 'jquery'

export default class ConfirmDefaultFundingGroup {
  constructor () {
    this.msdSettingsForm = $('form.edit_budgeting_service')
    this.saveButton = $('a.confirm_save')
    this.defaultFundingRadioButtons = $('input[name="budgeting_service[default_cases_to_msd_funded]"]')
    this.defaultFundingSelectedTrue = $('#budgeting_service_default_cases_to_msd_funded_true')
    this.confirmButton = $('.js-confirm-button')
    this.submitButton = $('.test-submit')

    this._updateButtons()
  }

  init () {
    this.defaultFundingRadioButtons.on('change',
      this._updateButtons.bind(this))

    this.saveButton.on('click',
      this._submitForm.bind(this))
  }

  // private methods

  _submitForm () {
    this.msdSettingsForm.submit()
  }

  _updateButtons () {
    if (this.defaultFundingSelectedTrue.is(':checked')) {
      this.confirmButton.removeClass('hide')
      this.submitButton.addClass('hide')
    } else {
      this.submitButton.removeClass('hide')
      this.confirmButton.addClass('hide')
    }
  }
}
