import $ from 'jquery'

import axios from 'axios'
import FileSaver from 'file-saver'

const SPINNER_HTML = '<div class="spinner spinner--on-button"></div>'

export default function () {
  // Disable the client export button after clicked to prevent DDOS from spam
  // clicking exports (it's a heavy operation).
  $('[data-download-button="true"]').click(async event => {
    const downloadButton = $(event.target)
    const url = downloadButton.data('download-url')
    const filename = downloadButton.data('download-filename')

    const originalButtonContents = downloadButton.html()

    // disabled the button add a spinner
    downloadButton.addClass('disabled').attr('disabled', true)
    downloadButton.html(SPINNER_HTML + originalButtonContents)

    const response = await axios({ url, method: 'GET', responseType: 'blob' })

    FileSaver.saveAs(new Blob([response.data]), filename)

    // remove the spinner, leave the button disabled
    downloadButton.html(originalButtonContents)
    downloadButton.removeClass('disabled').removeAttr('disabled')
  })
}
