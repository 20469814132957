import $ from 'jquery'
import FormSectionToggler from '~/javascript/application/shared/FormSectionToggler'

export default class PartnerFieldGroupCanceller extends FormSectionToggler {
  constructor (sectionToToggle) {
    super(sectionToToggle)
    this.clientPartnerSection = $(this.container).find('.form-section-to-toggle')
    this.clientPartnerFields = [
      '*[name="client[partner_first_name]"]',
      '*[name="client[partner_last_name]"]',
      '*[name="client[partner_date_of_birth_day]"]',
      '*[name="client[partner_date_of_birth_month]"]',
      '*[name="client[partner_date_of_birth_year]"]',
      '*[name="client[partner_ethnicity]"]',
      '*[name="client[partner_referral_point]"]',
      '*[name="client[partner_gender]"]:checked',
    ]
    this.clientFormSubmit = $('#submit_client_form')
  }

  init () {
    super.init()
    this._bindFieldGroupCancel()
  }

  // private methods

  _allFieldsEmpty () {
    let hasNonEmptyField = false
    this.clientPartnerFields.forEach((partnerField) => {
      if ($(partnerField).val()) {
        hasNonEmptyField = true
      }
    })

    return !hasNonEmptyField
  }

  _bindFieldGroupCancel () {
    this.clientFormSubmit.click(() => {
      if (!this.clientPartnerSection.hasClass('hide') && this._allFieldsEmpty()) {
        this._collapseSection()
      }
    })
  }
}
