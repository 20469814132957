import $ from 'jquery'
import AppointmentFormTimePeriod from '~/javascript/application/appointment/AppointmentFormTimePeriod'

const NEW_FORM_SELECTOR = '#new_appointment'
const EDIT_FORM_SELECTOR = '[id^=\'edit_appointment_\']'

export default function () {
  let appointmentForm

  if ($(NEW_FORM_SELECTOR).length) {
    appointmentForm = new AppointmentFormTimePeriod(NEW_FORM_SELECTOR)
  } else if ($(EDIT_FORM_SELECTOR).length) {
    appointmentForm = new AppointmentFormTimePeriod(EDIT_FORM_SELECTOR)
  }

  if (appointmentForm) appointmentForm.init()
}
