import PartnerFieldGroupCanceller from '~/javascript/application/client/PartnerFieldGroupCanceller'
import MentorPermissionSelector from '~/javascript/application/client/MentorPermissionSelector'

export default function () {
  const sectionToToggle = document.querySelector('.form-section-to-toggle-container')

  const partnerFieldGroupCanceller = new PartnerFieldGroupCanceller(sectionToToggle)
  partnerFieldGroupCanceller.init()

  const mentorPermissionSelector = new MentorPermissionSelector('.client-form__field--allowed-mentor-ids')
  mentorPermissionSelector.init()
}
