import $ from 'jquery'

import DependentCheckableInput from '~/javascript/application/shared/DependentCheckableInput'
import ToggleDisable from '~/javascript/application/shared/ToggleDisable'
import ConfirmDefaultFundingGroup from '~/javascript/application/msd_settings/ConfirmDefaultFundingGroup'

export default function () {
  const FUNDED_TRUE_INPUT_ID = '#budgeting_service_msd_funded_true'
  const FUNDED_FALSE_INPUT_ID = '#budgeting_service_msd_funded_false'
  const SHARE_DATA_FALSE_INPUT_ID = '#budgeting_service_share_data_with_msd_false'
  const SHARE_DATA_INPUT_SELECTORS = `#budgeting_service_share_data_with_msd_true, ${SHARE_DATA_FALSE_INPUT_ID}`
  const DEFAULT_FUNDING_GROUP_FALSE = '#budgeting_service_default_cases_to_msd_funded_false'

  // If 'Service funded by MSD? - No' is set, 'Share data' is set to 'No'
  const msdSettingsDependentRadioButtons = new DependentCheckableInput({
    input:        $(FUNDED_FALSE_INPUT_ID),
    dependencies: $(SHARE_DATA_FALSE_INPUT_ID),
  })
  msdSettingsDependentRadioButtons.init()

  // If 'Service funded by MSD? - No' is set, 'Share data' options are disabled
  const msdSettingsToggleDisable = new ToggleDisable({
    disableOn:         $(FUNDED_FALSE_INPUT_ID),
    enableOn:          $(FUNDED_TRUE_INPUT_ID),
    dependentElements: $(SHARE_DATA_INPUT_SELECTORS),
  })
  msdSettingsToggleDisable.init()

  // Only include confirmation if default funding group is false on edit
  if ($(DEFAULT_FUNDING_GROUP_FALSE).is(':checked')) {
    const confirmDefaultFundingGroup = new ConfirmDefaultFundingGroup()
    confirmDefaultFundingGroup.init()
  }
}
