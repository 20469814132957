import $ from 'jquery'

/**
 * JQuery form section toggler that handles the hiding and showing of
 * a section on button click and re-setting abide validation accordingly.
 * NOTES:
 * - The section(s) to be hidden/revealed is wrapped in the class
 *   .form-section-to-toggle
 * - The toggler takes the class .form-section-toggler and wraps two buttons,
 *     .section-button__expand & .section-button__collapse.
 * - Add a Foundation .hide class (etc) to hide the expand or collapse button,
 *   depending on whether or not the section is expanded/collapsed by default.
 */
export default class FormSectionToggler {
  constructor (containerElement) {
    this.container = containerElement
    this.sectionToToggle = $(this.container).find('.form-section-to-toggle')
    this.toggler = $(this.container).find('.form-section-toggler')
    this.expandButton = $(this.toggler).find('.section-button__expand')
    this.collapseButton = $(this.toggler).find('.section-button__collapse')
  }

  init () {
    this._bindSectionToggle()
  }

  // private methods

  _expandSection () {
    $(this.sectionToToggle).removeClass('hide')
    $(this.expandButton).addClass('hide')
    $(this.collapseButton).removeClass('hide')

    const inputs = $(this.sectionToToggle).find('input, select, textarea')
    inputs.removeAttr('data-abide-ignore') // enable validation
  }

  _collapseSection () {
    $(this.sectionToToggle).addClass('hide')
    $(this.collapseButton).addClass('hide')
    $(this.expandButton).removeClass('hide')

    const inputs = $(this.sectionToToggle).find('input, select, textarea')
    inputs.attr('data-abide-ignore', true) // disable validation

    // clear the values
    inputs.not('input[type="radio"]').val('')
    $(this.sectionToToggle).find('input[type="radio"]').prop('checked', false)

    // reset form validation
    $(this.sectionToToggle).parents('form').foundation('validateForm')
  }

  _bindSectionToggle () {
    $(this.toggler).click(() => {
      if ($(this.sectionToToggle).hasClass('hide')) {
        this._expandSection()
      } else {
        this._collapseSection()
      }
    })
  }
}
