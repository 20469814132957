import { isBoolean, parseInt } from 'lodash'

export default class DependentInput {
  constructor ({ input, dependedOnInput, disableWhen }) {
    this.input = input
    this.dependedOnInput = dependedOnInput
    this.disableWhen = disableWhen
  }

  init () {
    this._addDependedOnInputEventListener()
    this._updateInput() // update it once on init/page-load
  }

  // private methods

  _addDependedOnInputEventListener () {
    this.dependedOnInput.on('input change', this._updateInput.bind(this))
  }

  _valueIsTruthy (value) {
    if (isBoolean(value)) { return value }
    if (!value || isNaN(value)) { return false }

    return parseInt(value) !== 0
  }

  _updateInput () {
    let disableInput
    if (this.disableWhen) {
      disableInput = this.disableWhen(this.dependedOnInput)
    } else {
      disableInput = !this._valueIsTruthy(this.dependedOnInput.val())
    }

    this.input.attr('disabled', disableInput)
  }
}
