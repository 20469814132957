import $ from 'jquery'

export default class ResolveGoal {
  constructor (unresolvedGoal, modal, confirmButton, submitButton) {
    this.unresolvedGoal = unresolvedGoal
    this.modal = modal
    this.confirmButton = confirmButton
    this.submitButton = submitButton

    this.form = $($(this.unresolvedGoal).closest('form'))
    this.goalId = $(this.unresolvedGoal).attr('goal_id')
    this.fullyReachedGoal = $(this.unresolvedGoal).find('input[type=radio][value=10]')
    this.goalCheckBox = $(this.unresolvedGoal).find('input[type=checkbox]')
    this.goalResolveBox = $(this.unresolvedGoal).find('.goal-check-in__resolve-box')
    this.modalContent = $(this.modal).find('.modal__body  blockquote[case_goal_id=' + this.goalId + ']')
    this._updateButtons()
  }

  init () {
    this.fullyReachedGoal.on('change',
      this._updateResolveGoalCheckBox.bind(this))

    this.goalCheckBox.on('change',
      this._applyResolveStyle.bind(this))

    this.form.on('reset',
      this._removeResolveStyle.bind(this))

    this.goalCheckBox.on('change',
      this._updateModalBody.bind(this))

    this._updateModalBody()
  }

  // private methods

  _applyResolveStyle () {
    if (this.goalCheckBox.is(':checked')) {
      this.goalResolveBox.addClass('goal-check-in__resolve-box--selected')
    } else {
      this._removeResolveStyle()
    }
  }

  _removeResolveStyle () {
    this.goalResolveBox.removeClass('goal-check-in__resolve-box--selected')
  }

  _updateResolveGoalCheckBox () {
    if (this.fullyReachedGoal.is(':checked')) {
      this.goalCheckBox.prop('checked', true)
      this._applyResolveStyle()
      this._updateModalBody()
    }
  }

  _updateModalBody () {
    if (this.goalCheckBox.is(':checked')) {
      this.modalContent.removeClass('hide')
    } else {
      this.modalContent.addClass('hide')
    }
    this._updateButtons()
  }

  _updateButtons () {
    if ($(this.modal).find('.modal__body blockquote:not(.hide)[case_goal_id]').length > 0) {
      this.confirmButton.removeClass('hide')
      this.submitButton.addClass('hide')
    } else {
      this.submitButton.removeClass('hide')
      this.confirmButton.addClass('hide')
    }
  }
}
