import $ from 'jquery'
import heredoc from 'heredocument'

export default function () {
  const addFundingGroupButton = $('#js-add-funding-group')
  const fundingGroups = $('#js-funding-groups')

  $(addFundingGroupButton).click(() => {
    // Use current timestamp as unique key for funding group
    const ms = new Date().getTime()

    const idAttribute = `budgeting_service_funding_groups_attributes_${ms}_name`
    const nameAttribute = `budgeting_service[funding_groups_attributes][${ms}][name]`

    const currentGroupCount = $(fundingGroups).children().length

    const fundingGroupGrid = heredoc`
        <div class="grid-x grid-margin-x">
          <div class="cell medium-3">
            <label for="extra_funding_group" class="bold-label pad-top">
              Extra funding group ${currentGroupCount + 1}
            </label>
          </div>
          <div class="cell medium-4">
            <input type="text" id=${idAttribute} name=${nameAttribute} class="pad-top">
          </div>
          <div class="cell auto" />
        </div>
      `

    $(fundingGroups).append(fundingGroupGrid)
  })
}
