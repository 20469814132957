import $ from 'jquery'

export default class MentorPermissionSelector {
  constructor (fieldClass) {
    this.mentorSelect = $(fieldClass + ' select')
    this.addButton = $(fieldClass + ' button.js-add-allowed-mentor')
    this.mentorList = $(fieldClass + ' ul.js-allowed-mentors')
    this.mentorAllList = $(fieldClass + ' .js-mentor-all-mentors')
  }

  init () {
    this._bindAddButton()
    this._bindRemoveButtons()
  }

  // private methods

  _bindAddButton () {
    this.addButton.on('click', () => {
      this._addMentor()
    })
  }

  _addMentor () {
    const mentorId = this.mentorSelect.val()

    if (mentorId === '') {
      return
    }

    const mentorName = this.mentorSelect.find('option:selected').text()
    const mentorListItem = this._buildMentorListItem(mentorId, mentorName)

    if (mentorId === 'anyone') {
      this.mentorList.children('li').each((_index, element) => {
        this._removeItem($(element))
      })
      this.mentorAllList.addClass('hide')
    } else {
      const anyMentorItem = this.mentorList.find('input[name="client[allow_any_mentor]"]').parent()

      if (anyMentorItem.length > 0) {
        this._removeItem(anyMentorItem)
      }
    }

    this.mentorList.append(mentorListItem)
    this.mentorSelect.find('option:selected').remove()
  }

  _buildMentorListItem (mentorId, mentorName) {
    const listItem = $('<li></li>')
    let hiddenInput

    if (mentorId === 'anyone') {
      hiddenInput = $('<input type="hidden" name="client[allow_any_mentor]" value="1" />')
    } else {
      hiddenInput = $('<input type="hidden" name="client[allowed_mentor_ids][]" />')
      hiddenInput.val(mentorId)
    }

    const removeButton = $('<button class="button clear alert no-padding-vertical">Remove <i class="bi bi-x-lg" /></button>')
    const nameSpan = $('<span></span>')

    nameSpan.text(mentorName)
    listItem.append(nameSpan)
    listItem.append(hiddenInput)
    listItem.append(removeButton)

    this._bindRemoveButton(removeButton)

    return listItem
  }

  _bindRemoveButton (removeButton) {
    removeButton.on('click', (event) => {
      event.preventDefault()

      this._removeItem(removeButton.parent())
    })
  }

  _removeItem (listItemElement) {
    const hiddenInput = listItemElement.find('input')
    const mentorName = listItemElement.find('span').text()
    let mentorId

    if (mentorName === 'Any mentor') {
      mentorId = 'anyone'
    } else {
      mentorId = hiddenInput.val()
    }
    this._addMentorToSelect(mentorId, mentorName)

    if (mentorId === 'anyone') {
      this.mentorAllList.removeClass('hide')
    }

    listItemElement.remove()
  }

  _addMentorToSelect (mentorId, mentorName) {
    if (mentorId === 'anyone') {
      this.mentorSelect.append($('<option></option>').val(mentorId).text(mentorName))
    } else {
      const existingMentorOptions = this.mentorSelect.find('option:not([value=\'\'])')

      let optionInserted = false
      existingMentorOptions.each((_index, element) => {
        if (mentorName < $(element).text() || $(element).val() === 'anyone') {
          $(element).before($('<option></option>').val(mentorId).text(mentorName))

          optionInserted = true
          return false
        }
      })
      if (!optionInserted) {
        this.mentorSelect.append($('<option></option>').val(mentorId).text(mentorName))
      }
    }
  }

  _bindRemoveButtons () {
    this.mentorList.find('button').each((_index, element) => {
      this._bindRemoveButton($(element))
    })
  }
}
