import $ from 'jquery'
import SectionToggler from '~/javascript/application/shared/SectionToggler'
import ResolveGoal from '~/javascript/application/case_goals_check_in/ResolveGoal'
import ConfirmGoalCompleted from '~/javascript/application/case_goals_check_in/ConfirmGoalCompleted'

export default function () {
  const sectionsToToggle = document.querySelectorAll('.section-to-toggle-container')

  sectionsToToggle.forEach(section => {
    const formSectionToggler = new SectionToggler(section)
    formSectionToggler.init()
  })

  const modal = $('.modal')
  const confirmButton = $('.js-resolve-confirm-button')
  const submitButton = $('.js-save-case-goals-check-in')
  const unresolvedGoals = document.querySelectorAll('.goal-check-in--unresolved')

  unresolvedGoals.forEach(goal => {
    const resolveGoal = new ResolveGoal(goal, modal, confirmButton, submitButton)
    resolveGoal.init()
  })

  const confirmGoalCompleted = new ConfirmGoalCompleted()
  confirmGoalCompleted.init()
}
