import $ from 'jquery'
import moment from 'moment'

export default function () {
  setupFieldPairing('.client-form__field--date-of-birth', '.client-form__field--age-range')
  setupFieldPairing('.client-form__field--partner-date-of-birth', '.client-form__field--partner-age-range')
}

const setupFieldPairing = (dobFieldSelector, rangeFieldSelector) => {
  disableAgeRangeWhenDateOfBirthFilled(dobFieldSelector, rangeFieldSelector)

  $(dobFieldSelector).on('input', () => {
    disableAgeRangeWhenDateOfBirthFilled(dobFieldSelector, rangeFieldSelector)
    $(rangeFieldSelector).closest('form').foundation()
  })
}

const disableAgeRangeWhenDateOfBirthFilled = (dobFieldSelector, rangeFieldSelector) => {
  if ($(dobFieldSelector).length === 0) {
    return
  }

  const day = $(dobFieldSelector + ' input[placeholder=DD]').val()
  const month = $(dobFieldSelector + ' input[placeholder=MM]').val()
  const year = $(dobFieldSelector + ' input[placeholder=YYYY]').val()

  const dob = moment().year(year).month(month - 1).date(day)
  const age = moment().diff(dob, 'years')
  let ageRange

  if (age < 18) {
    ageRange = 'under_18'
  } else if (age < 26) {
    ageRange = '18_to_25'
  } else if (age < 36) {
    ageRange = '26_to_35'
  } else if (age < 46) {
    ageRange = '36_to_45'
  } else if (age < 66) {
    ageRange = '46_to_65'
  } else {
    ageRange = 'over_65'
  }

  if (day !== '' || month !== '' || year !== '') {
    $(rangeFieldSelector + ' select').val(ageRange)
    $($(rangeFieldSelector).closest('form')).foundation('validateInput', $(rangeFieldSelector + ' select'))
    $(rangeFieldSelector + ' select').attr('disabled', true)
    $(rangeFieldSelector + ' select').attr('data-abide-ignore', true)
  } else {
    if ($(rangeFieldSelector + ' select').attr('disabled')) {
      $(rangeFieldSelector + ' select').removeAttr('disabled')
      $(rangeFieldSelector + ' select').removeAttr('data-abide-ignore')
      $(rangeFieldSelector + ' select').val(null)
    }
  }
}
