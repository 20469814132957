// Intended use:
//  When an element is changed (e.g. an input is checked), disable some dependent elements
//  When a (maybe different) element is changed, undisable the dependent elements
// Use case:
//  If a user answers a question 'Yes', there is a dependent question to answer
//  If the user answers the main question 'No', the dependent question is not relevant, and therefore must be disabled.

export default class ToggleDisable {
  constructor ({ disableOn, enableOn, dependentElements, propertyToCheck = 'checked' }) {
    this.disableOn = disableOn
    this.enableOn = enableOn
    this.dependentElements = dependentElements
    this.propertyToCheck = propertyToCheck
  }

  init () {
    this._bindDisable()
    this._bindEnable()
  }

  // private methods

  _bindDisable () {
    this.disableOn.change(event => {
      if (event.target[this.propertyToCheck]) {
        this._disable()
      }
    })
  }

  _bindEnable () {
    this.enableOn.change(event => {
      if (event.target[this.propertyToCheck]) {
        this._enable()
      }
    })
  }

  _disable () {
    this.dependentElements.prop('disabled', true)
  }

  _enable () {
    this.dependentElements.prop('disabled', false)
  }
}
