import $ from 'jquery'
import AutoDiscardCallout from '~/javascript/application/auto_delete_settings/AutoDiscardCallout'
import DependentInput from '~/javascript/application/shared/DependentInput'

export default function () {
  const casePeriodDependentInputs = new DependentInput({
    input:           $('#budgeting_service_auto_discard_cases_period'),
    dependedOnInput: $('#budgeting_service_auto_discard_cases'),
    disableWhen:     input => !input.is(':checked'),
  })
  casePeriodDependentInputs.init()

  const clientPeriodDependentInputs = new DependentInput({
    input:           $('#budgeting_service_auto_discard_clients_period'),
    dependedOnInput: $('#budgeting_service_auto_discard_clients'),
    disableWhen:     input => !input.is(':checked'),
  })
  clientPeriodDependentInputs.init()

  const casesWarningCallout = new AutoDiscardCallout({
    callout:       $('#auto_discard_cases_callout'),
    calloutPeriod: $('#auto_discard_cases_callout_period'),

    enableCheckbox: $('#budgeting_service_auto_discard_cases'),
    periodSelect:   $('#budgeting_service_auto_discard_cases_period'),
  })
  casesWarningCallout.init()

  const clientsWarningCallout = new AutoDiscardCallout({
    callout:       $('#auto_discard_clients_callout'),
    calloutPeriod: $('#auto_discard_clients_callout_period_1, ' +
                     '#auto_discard_clients_callout_period_2'),

    enableCheckbox: $('#budgeting_service_auto_discard_clients'),
    periodSelect:   $('#budgeting_service_auto_discard_clients_period'),
  })
  clientsWarningCallout.init()
}
