import $ from 'jquery'

export default function () {
  // This is a simple function that enables the reset button on a form when there is a change event

  const resetButton = $('button.button[disabled][type="reset"]')

  const form = $(resetButton).closest('form')

  $(form).on('change', function () {
    toggleResetButton(false)
  })

  // disable the button again when the form is reset
  $(form).on('reset', function () {
    toggleResetButton(true)
  })

  function toggleResetButton (disabled) {
    $(resetButton).prop('disabled', disabled)
    $(resetButton).toggleClass('disabled', disabled)
  }
}
