import $ from 'jquery'

export default function () {
  function checkboxGroup (element, _required, _parentElement) {
    const enclosingElement = element.closest('fieldset')
    const checkboxes = enclosingElement.find('input[type="checkbox"]')
    const anyChecked = checkboxes.is(':checked')

    if (anyChecked) {
      checkboxes.parent().removeClass('is-invalid-label')
      enclosingElement.find('span.form-error').hide()
      return true
    } else {
      checkboxes.parent().addClass('is-invalid-label')
      enclosingElement.find('span.form-error').show()
      return false
    }
  }

  function checkboxGroupIfTextareaFilledIn (element, _required, _parentElement) {
    // If textarea has text entered, one or more of the checkboxes must be checked

    const enclosingElement = element.closest('.validate-checkbox-group-if-textarea-filled-out')
    const formFieldTextArea = enclosingElement.find('textarea').get(0)

    // Only validate the checkboxes if something is entered into the textarea
    if (formFieldTextArea.value.trim() === '') return true

    const checkedCount = enclosingElement.find('input:checked').length

    // If any checkboxes are checked, the form section is valid
    if (checkedCount > 0) return true

    enclosingElement.find('span.form-error').show()
    return false
  }

  function afterDate (element, _required, _parentElement) {
    // For validating against a supplied date

    const endDate = element[0].value
    const startDate = element.data('start-date')

    return new Date(endDate) > new Date(startDate)
  }

  function beforeDate (element, _required, _parentElement) {
    // For validating against a supplied date

    const date = element[0].value
    const endDate = element.data('end-date')

    return new Date(endDate) > new Date(date)
  }

  function beforeOtherInputDate (element, _required, _parentElement) {
    // For validating against the value of another input, indicated by its ID

    const startDate = element[0].value
    const endDateInputId = element.data('end-date-input-id')
    const endDateInputElement = $('#' + endDateInputId)

    if (endDateInputElement.length <= 0) {
      return true
    }

    const endDate = endDateInputElement[0].value

    return new Date(startDate) <= new Date(endDate)
  }

  function afterOtherInputDate (element, _required, _parentElement) {
    // For validating against the value of another input, indicated by its ID

    const endDate = element[0].value
    const startDateInputId = element.data('start-date-input-id')
    const startDateInputElement = $('#' + startDateInputId)

    if (startDateInputElement.length <= 0) {
      return true
    }

    const startDate = startDateInputElement[0].value

    return new Date(endDate) >= new Date(startDate)
  }

  function greaterThan (element, required, _parentElement) {
    if (!required) return true

    const inputValue = element[0].value
    const min = element.data('greater-than')

    return parseFloat(inputValue) > min
  }

  window.Foundation.Abide.defaults.patterns.phoneNumber = /^[+0-9 -x]+/
  window.Foundation.Abide.defaults.validators.checkboxGroup = checkboxGroup
  window.Foundation.Abide.defaults.validators.checkboxGroupIfTextareaFilledIn = checkboxGroupIfTextareaFilledIn
  window.Foundation.Abide.defaults.validators.afterDate = afterDate
  window.Foundation.Abide.defaults.validators.beforeDate = beforeDate
  window.Foundation.Abide.defaults.validators.beforeOtherInputDate = beforeOtherInputDate
  window.Foundation.Abide.defaults.validators.afterOtherInputDate = afterOtherInputDate
  window.Foundation.Abide.defaults.validators.greaterThan = greaterThan
}
