import $ from 'jquery'

export default function () {
  const passwordSections = $('.password')

  passwordSections.each(function (idx, passwordSection) {
    const passwordInput = $(passwordSection).find('input').first()
    const togglePasswordLink = $(passwordSection).find('.toggle-password').first()

    $(togglePasswordLink).click(function () {
      if ($(passwordInput).attr('type') === 'password') {
        $(togglePasswordLink).text('Hide password')
        $(passwordInput).attr('type', 'text')
      } else {
        $(togglePasswordLink).text('Show password')
        $(passwordInput).attr('type', 'password')
      }
    })
  })
}
