const DEVISE_SESSION_TIMEOUT = 60 * 60 * 1000 // 60 minutes

export default class SessionExpire {
  constructor () {
    this.bindCheckInterval()
  }

  bindCheckInterval () {
    this.resetInterval()
  }

  reloadPage () {
    if (location.pathname !== '/users/login') {
      location.reload()
    }
  }

  resetInterval () {
    clearInterval(this.checkInterval)
    this.checkInterval = setInterval(
      this.reloadPage,
      // Add one second to the devise session timeout, otherwise it's possible
      // to hit the server before the session has expired. If that happes it
      // leads to permanently refreshing the session.
      DEVISE_SESSION_TIMEOUT + 1000,
    )
  }
}
