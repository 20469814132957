import $ from 'jquery'
import DependentCheckableInput from '~/javascript/application/shared/DependentCheckableInput'

export default function () {
  const mentorAllClientsDependentCheckboxes = new DependentCheckableInput({
    input:        $('#user_can_mentor_all_clients'),
    dependencies: $('#user_is_mentor, #user_can_view_all_clients'),
  })
  mentorAllClientsDependentCheckboxes.init()

  const staffManagerDependentCheckboxes = new DependentCheckableInput({
    input:        $('#user_is_staff_manager'),
    dependencies: $('#user_can_view_all_clients, #user_can_manage_all_clients'),
  })
  staffManagerDependentCheckboxes.init()

  const manageAllClientsDependentCheckbox = new DependentCheckableInput({
    input:        $('#user_can_manage_all_clients'),
    dependencies: $('#user_can_view_all_clients'),
  })
  manageAllClientsDependentCheckbox.init()
}
